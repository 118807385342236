import React, { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "leaflet.heat";

interface HeatMapProps {
  points: number[][];
}

const HeatMap = ({ points }: HeatMapProps) => {
  const context = useLeafletContext();
  const lastLayerRef = useRef<L.Layer>();

  useEffect(() => {
    if (lastLayerRef.current) {
      context.layerContainer?.removeLayer(lastLayerRef.current);
      context.map.removeLayer(lastLayerRef.current);
    }

    const newPoints = points.map((p) => [p[1], p[0], 200]);

    // @ts-ignore
    const layer = L.heatLayer(newPoints, { radius: 15, blur: 15 });

    context.map.addLayer(layer);

    lastLayerRef.current = layer;
  }, [points, context]);

  return null;
};

export default HeatMap;
